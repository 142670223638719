// entry point: renderer

// firebase
import firebase from "js/firebase";
firebase.initializeApp(window.baiFirebase.config);

// because we mix namespaces will ES6 modules, certain modules need to be pre-loaded
// this is done dynamically, via eager (pre-bundled) imports due to DbRouter having
// an dependency on firebase.initializeApp being already called

// shared
import(/* webpackMode: "eager" */ "js/preload");

// renderer
window.thumbnailRendererPromise = import(/* webpackMode: "eager" */ "js/renderer/renderer"); // <-- entry point
